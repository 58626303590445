import React, {useState, useEffect} from "react";
import {
    Row,
    Card,
    Button,
    InputGroup,
    FormControl, Col,
    Container
} from "react-bootstrap";
import Deck from "../components/Deck"
import Loader from "react-loader-spinner";
const mtg = require('mtgsdk');

let backgroundImgStyle = {
    color: "white",
    background: "radial-gradient(circle, rgba(250,255,147,1) 0%, rgba(85,34,4,1) 90%)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100vh",
    padding: "0",
};

const loadingBackground = {
    background:"rgba(0,0,0,.5)",
    textAlign:"center",
    height:"100%"
}

const buyNowButtonStyle = {
}

const CardSearch = () => {
    const [searchName, setSearchName] = useState("");
    const [validSearch, setValidSearch] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [cards, setCards] = useState([]);
    const [searching, setSearching] = useState(false);
    const [complete, setComplete] = useState(false);
    const [index, setIndex] = useState(-1);
    const [timedOut, setTimedOut] = useState(false);
    const [mobile, setMobile] = useState(true);
    const [foundCard, setFoundCard] = useState("");
    const [resultsLength, setResultsLength] = useState(0);
    const [cardTitle, setCardTitle] = useState("");
    const [setName, setSetName] = useState("");
    const [reload, setReload] = useState(false);

    const handleForm = (e) => {
        setSearchName(e.target.value);
    }

    const handleKey = (e) => {
        if (e.key == 'Enter') {
            handleSearch();
        }
    }

    function deckCallback(childData){
        if(childData===true){
            setIndex(index-1);
        }
        if(index===0){
            setReload(true);
            const timer = setTimeout(()=>{
                loadDeck();
                setReload(false);
                clearTimeout(timer);

            },3000);
        }
    }

    useEffect(()=>{
        if(complete & resultsLength>0 & !reload) {
            console.log(index);
            console.log(cards[index]);
            setCardTitle(cards[index].name);
            setSetName(cards[index].set);
        }

    },[index, reload])

    const handleSearch = (e) => {
        setSearching(true);
        setSearchResults([])
        const searchTimeout = 1500;
        let timer = setTimeout(timeoutHandler,searchTimeout)
        mtg.card.all({name: searchName, pageSize: 1})
            .on('data', card => {
                clearTimeout(timer);
                timer = setTimeout(timeoutHandler,searchTimeout);
                if(!complete){
                    setFoundCard("Located: " +card.name)
                    setSearchResults(searchResults => [...searchResults, card])
                }else{
                    return(0)
                }
            })
    }

    function loadDeck(){
        let tempCards = searchResults.slice(0,10).filter(checkImgUrl);
        setCards(tempCards);
        setResultsLength(tempCards.length);
        setIndex(tempCards.length-1);
        console.log(tempCards);
        console.log(index);
        setComplete(true);
    }

    useEffect(()=>{
        if((searchResults.length>10 & !complete) | (timedOut & !complete)){
            loadDeck();
        }
    },[searchResults, timedOut])

    useEffect(()=>{
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            setMobile(true);
        }else{
            setMobile(false);
        }
    },[])

    function timeoutHandler(){
        setTimedOut(true);
        console.log("Search timed out");
    }

    function checkImgUrl(result){
        if('imageUrl' in result){
            return(result);
        }
    }

    function renderSearchBar(){
        return(
                <Row className='d-flex justify-content-center'>
                    <Card style={{background:"rgba(0,0,0,0)", borderWidth:"0px", borderRadius:'0px', margin: '0px'}}>
                            <br/>
                            <h3
                                className="text-center"
                                style={{paddingTop: "1rem"}}>
                                <b>Multiverse Locator</b>
                            </h3>
                        <Card.Body>
                            <InputGroup className="mb-3">
                                <FormControl
                                    aria-label="Default"
                                    aria-describedby="inputGroup-sizing-default"
                                    onChange={handleForm}
                                    onKeyPress={handleKey}
                                    placeholder="Enter a card name or name fragment..."
                                />
                            </InputGroup>
                            <Row className="m-auto align-self-center">
                                <Button size="lg" variant="danger" disabled={validSearch} onClick={handleSearch} >
                                    Triangulate!</Button>
                            </Row>
                        </Card.Body>
                    </Card>
                </Row>
        );
    }

    function renderLoading(){
        return(
            <div style={loadingBackground}>
                <Row className='d-flex justify-content-center'>
                    <Col xs={12}>
                        <Loader
                            style={{paddingTop:"25vh"}}
                            type="BallTriangle"
                            color="#ffffff"
                            height="20vh"
                            width="20vh"
                            timeout={5000} //5 secs
                        />
                        <p style={{paddingTop:"1rem", textAlign:"center"}}>{foundCard}</p>
                    </Col>
                </Row>
            </div>
        )
    }

    function renderDeck(){
        return(
            <div className="text-center">
                <Container fluid>
                    <Row className="d-flex justify-content-center">
                        <Deck cards={cards} mobile={mobile} callback={deckCallback}/>
                    </Row>
                    <Row>
                        <h5 style={{paddingTop:"1rem"}}><b>{cardTitle}</b>: {setName}</h5>
                    </Row>
                    <Row className="m-auto align-self-center" style={{paddingTop:"60vh"}}>
                        <Button size="lg" variant="danger" style={buyNowButtonStyle}>Get Today</Button>
                    </Row>
                </Container>
            </div>
        )
    }

    function render(){
        if(complete){
            return (
                renderDeck()
            )
        }
        if(!searching){
            return(
                renderSearchBar()
            )
        }else{
            return(
                renderLoading()
            )
        }
    }

    return (
    <div style={backgroundImgStyle}>
        {render()}
    </div>
    );
}

export default CardSearch;
