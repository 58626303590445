import React, {useState, useEffect} from "react";
import {Jumbotron, Row, ListGroup, Col, Button} from "react-bootstrap";

const Cart = () => {
    let [lineItems, setLineItems] = useState([]);
    const imgStyle = {
        width:"auto",
        height:"120px"
    }

    const handleRemoveItem = (e) =>{
        localStorage.removeItem('cart:'+e.target.id);
        updateLineItems();
        setMedia(renderMedia());
    }

    function updateLineItems(){
        setLineItems([])
        for (const key of Object.keys(localStorage)) {
            if (key.includes('cart:')) {
                let item = JSON.parse((localStorage.getItem(key)));
                setLineItems(lineItems => [...lineItems, item])
            }
        }
        console.log(lineItems);
    }

    function renderMedia(){
        return (
            lineItems.map(item => (
                    <ListGroup.Item >
                        <Row>
                        <Col xs={3}>
                        <img src={item.imageUrl}
                             alt={item.name}
                             class="d-inline"
                             style={imgStyle}
                        />
                        </Col>
                        <Col xs={5}>
                            <h6><b>{item.name} -</b> {item.subName}</h6>
                            <p>Order Qty: {item.qty}</p>
                        </Col>
                        <Col xs={4}>
                            <Button id={item.key} variant="outline-danger" onClick={handleRemoveItem}>Remove</Button>
                        </Col>
                        </Row>
                    </ListGroup.Item>
            ))
        )
    }
    let [media, setMedia] = useState(renderMedia());

    useEffect(() => {
        updateLineItems();
        setMedia(renderMedia());
        console.log(media);
    }, []);

    return(
    <div>
        <Row className="m-auto align-self-center">
        <Jumbotron>
            <h1>My Shopping Cart</h1>
        </Jumbotron>
        <ListGroup>
        {renderMedia()}
        </ListGroup>
        </Row>
    </div>
)
};

export default  Cart;
