import React, {useEffect} from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Header from "./components/header";
import Home from "./pages/home";
import Cart from "./pages/cart";
import CardPage from "./pages/card";
import CardSearch from "./pages/search";
import Boxes from "./pages/boxes"
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
   useEffect(() => {
       localStorage.setItem('cart', JSON.stringify[{default:'default'}]);
   },[]);
    return (
        <div className="App">
            <Router>
                <Header/>
                <Switch>
                    <Route exact path="/">
                        <Home/>
                    </Route>
                    <Route exact path="/search">
                        <CardSearch/>
                    </Route>
                    <Route exact path="/cart">
                        <Cart/>
                    </Route>
                    <Route exact path="/demo">
                        <CardPage/>
                    </Route>
                    <Route exact path="/boxes">
                        <Boxes/>
                    </Route>

                </Switch>
            </Router>
        </div>
    );
}

export default App;
