import React from "react";
import {Row, Jumbotron} from "react-bootstrap";
import ProductItem from "../components/ProductItem";
import boxInventory from "../media/boxInventory";
export default function Boxes(){
    let backgroundImgStyle = {
        background: "radial-gradient(circle, rgba(250,255,147,1) 0%, rgba(85,34,4,1) 90%)",
        backgroundAttachment: "fixed",
        height: "100vh",
        padding: "0",
    };

    function renderBoxes(){
        const boxes = boxInventory();
        return(
            boxes.map(box => (
                <ProductItem data={box}/>
            ))
        )
    }

    return(
        <div style={backgroundImgStyle}>
            <Jumbotron>
                <br/>
                <h3
                    className="text-center"
                    style={{padding: "5px"}}>
                    <b>Buy a Box!</b>
                </h3>
            </Jumbotron>
            <Row>
                {renderBoxes()}
            </Row>
        </div>
    )
}
