import React from "react";
import "./ProductItem.css"
import {
    Jumbotron,
    Col,
    Card,
    Button,

} from "react-bootstrap";
import {Link} from "react-router-dom";

export default function ProductItem(props) {
    const imgSize = "24rem";
    const bgSize = "250px";
    const btmPad = "12rem";
    let floatStyle = {
        height: "200px",
        width: "200px",
        background: "rgb(200, 200, 200, 0.0)",
        padding: "10px",
    }
    let imgStyle = {
        height: imgSize,
        width: "auto",
        paddingBottom: btmPad,
    }
    let backgroundCardStyle = {
        background: "radial-gradient(circle, rgba(255,193,74,1) 0%, rgba(255,255,255,0) 65%)",
        borderRadius: "125px",
        width: bgSize,
        height: bgSize,
        margin: "1rem",
        borderWidth: "0px",
    }
    let textCardStyle = {
        height: "100%",
        margin: "1rem",
        background: "rgba(255,255,255,0.65)",
        padding: "1rem",

    }

    function makeid(length) {
        var result = [];
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result.push(characters.charAt(Math.floor(Math.random() *
                charactersLength)));
        }
        return result.join('');
    }

    const handleCartAdd = (e) => {
        const key = makeid(10);
        let cartObj = {
            name: props.data.name,
            subName: "",
            imageUrl: props.data.src,
            price: props.data.price,
            qty: 1,
            key: key,
        }
        localStorage.setItem('cart:' + key, JSON.stringify(cartObj));
        console.log(Object.keys(localStorage));
    }

    return (
        <Col xs={12} md={4} lg={3} style={{paddingTop: "1rem"}}>
            <Card style={textCardStyle}>
                <Jumbotron style={{marginTop: "1rem", width: "100%", textAlign: "Center"}}><h2><b>{props.data.name}</b>
                </h2></Jumbotron>
                <row className='d-flex justify-content-center'>
                    <Card style={backgroundCardStyle}>
                        <div className="floating" style={floatStyle}>
                            <img src={props.data.src} style={imgStyle}/>
                        </div>
                    </Card>
                </row>
                <Card.Body>
                    <p style={{textAlign: "left"}}>{props.data.description}</p>
                    <span className="d-inline-block">
                      <Link to="/cart">
                            <Button
                                variant="danger"
                                size={"lg"}
                                onClick={handleCartAdd}
                                style={{
                                    position: 'absolute', //Here is the trick
                                    bottom: '1rem',
                                    right: '5%',
                                    width: '90%'
                                }}>
                                Add to cart: {props.data.price}
                            </Button>
                      </Link>
                    </span>
                </Card.Body>
            </Card>
        </Col>
    )
}
