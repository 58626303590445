import React from "react";
import {Navbar, Nav} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import "../index.css"
import "bootstrap/dist/css/bootstrap.min.css";


const Header = () => {
    const logoSrc = "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fupload.wikimedia.org%2Fwikipedia%2Fcommons%2Fthumb%2F1%2F1d%2FExplosion-417894_icon.svg%2F1021px-Explosion-417894_icon.svg.png&f=1&nofb=1"

    return (
        <Navbar bg="dark" expand="lg" variant="dark">
            <Navbar.Brand style={{fontFamily: 'myFont'}} href="/">
                <img
                    alt=""
                    src={logoSrc}
                    width="300"
                    height="75"
                    style={{width:"50px", height:"50px"}}
                />
                SameDay <b>TCG</b>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav" >
                <Nav className="mr-auto">
                    <LinkContainer to="/search">
                        <Nav.Link>Purchase Singles</Nav.Link>
                    </LinkContainer>
                </Nav>
                <Nav className="mr-auto">
                    <LinkContainer to="/cart">
                        <Nav.Link>Cart</Nav.Link>
                    </LinkContainer>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Header;
