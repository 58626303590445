import React, { useState } from 'react'
import { useSprings, animated, interpolate } from 'react-spring'
import { useGesture } from 'react-use-gesture'
import './deck.css'

export default function Deck(props){

    let marginTop = props.mobile ? "4rem" : "6rem"
    let marginRight;
    if(props.mobile){
        if(/iPhone/i.test(navigator.userAgent)){
            marginRight = "15vh";
        }else{
            marginRight = window.screen.availWidth*.25;
        }
    }else{
        marginRight = "5%";
    }
    // These two are just helpers, they curate spring data, values that are later being interpolated into css
    const to = (i) => ({ x: 50, y: i * -4, scale: 1, rot: -10 + Math.random() * 20, delay: i * 100 })
    const from = (i) => ({ x: 0, rot: 0, scale: 1.5, y: -1000 })
    // This is being used down there in the view, it interpolates rotation and scale into a css transform
    const trans = (r, s) => `perspective(2000px) rotateX(20deg) rotateY(${r / 10}deg) rotateZ(${r*1.0}deg) scale(${s})`
    const [gone] = useState(() => new Set()) // The set flags all the cards that are flicked out
    const [spring, set] = useSprings(props.cards.length, (i) => ({ ...to(i), from: from(i) })) // Create a bunch of springs using the helpers above

    // Create a gesture, we're interested in down-state, delta (current-pos - click-pos), direction and velocity
    const bind = useGesture(({ args: [index], down, delta: [xDelta], distance, direction: [xDir], velocity }) => {
        const trigger = velocity > 0.1 // If you flick hard enough it should trigger the card to fly out
        const dir = xDir < 0 ? -1 : 1 // Direction should either point left or right
        if (!down && trigger) gone.add(index) // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
        set((i) => {
            if (index !== i) return // We're only interested in changing spring-data for the current spring
            const isGone = gone.has(index)
            const x = isGone ? (0 + window.innerWidth) * dir : down ? xDelta : 0 // When a card is gone it flys out left or right, otherwise goes back to zero
            const rot = xDelta / 100 + (isGone ? dir * 10 * velocity : 0) // How much the card tilts, flicking it harder makes it rotate faster
            const scale = down ? 1.1 : 1 // Active cards lift up a bit

            props.callback(isGone);

            return { x, rot, scale, delay: undefined, config: { friction: 30, tension: down ? 800 : isGone ? 200 : 500 } }
        })
        if (!down && gone.size === props.cards.length) setTimeout(() => gone.clear() || set((i) => to(i)), 600)
    })

    // Now we're just mapping the animated values to our view, that's it. Btw, this component only renders once. :-)
    return spring.map(({ x, y, rot, scale }, i) => (
        <animated.div id="animationOuter" style={{paddingRight:marginRight, marginTop:marginTop, transform: interpolate([x, y], (x, y) => `translate3d(${x}px,${y}px,0)`)}} key={i}>
            {/* This is the card itself, we're binding our gesture to it (and inject its index so we know which is which) */}
            <animated.div id="animationInner" {...bind(i)} style={{ transform: interpolate([rot, scale], trans), backgroundImage: `url(${props.cards[i].imageUrl})` }} />
        </animated.div>
    ))

}
