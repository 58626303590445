// Content for the OceanLab's "Home" page

import React from "react";
import {Card, Col, Row, Button, Badge} from "react-bootstrap";
import {Link} from "react-router-dom";


const Home = () => {

    let backgroundImgStyle = {
        color: "white",
        background: "radial-gradient(circle, rgba(250,255,147,1) 0%, rgba(85,34,4,1) 90%)",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100%",
        padding: "0",
    };

    let titleStyle = {
        textAlign: "center",
        align: "center",
        color: "black",
        background: "rgba(0,0,0,0.0)",
        paddingBottom: "1rem",
        paddingRight: "2rem",
        paddingLeft: "2rem",
        paddingTop: "2rem",
        borderRadius: "25px",
    }

    let cardStyle = {
        background: "rgba(255,255,255,0.75)",
        padding: "10px",
        borderRadius: "10px",
        paddingBottom:"1rem"
    }

    let imgStyle = {
        borderRadius: "10px",
        display:"block",
        width:"auto",
        height:"auto",
    }

    return (
        <div style={backgroundImgStyle}>
            <Row className="m-auto align-self-center">
                <Col className="m-auto align-self-center" xs={12} md={6} lg={4} style={titleStyle}>
                    <Card style={cardStyle}>
                        <Card.Title>
                            <h2 style={{paddingTop: "1rem"}}>Same-day spellbook supplements</h2>
                        </Card.Title>
                        <Card.Img class="img-responsive center-block" src="https://loginportal.funnyjunk.com/pictures/Magic_8d8b94_5585340.jpg" style={imgStyle}/>
                        <Card.Body>
                            <h5>Cards to your door in hours, <i>not days</i>.</h5>
                            <p style={{padding: "1rem"}}>We've teamed with local drivers to provide MTG singles, packs,
                                and boxes at lightning bolt speed.</p>
                            <Link to={{
                                pathname: '/search',
                            }}>
                                <Row className="m-auto align-self-center">
                                    <Button variant="danger" size="lg" lock>Get your bombs NOW</Button>
                                </Row>

                            </Link>
                        </Card.Body>
                    </Card>
                    <br/>
                </Col>
            </Row>
        </div>
    );
};

export default Home;
