import React, {useState, useEffect} from "react";
import {
    Jumbotron,
    Row,
    Col,
    Card,
    Button,
    Dropdown,
    InputGroup,
    DropdownButton,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
function makeArr(startValue, stopValue, cardinality) {
    var arr = [];
    var step = (stopValue - startValue) / (cardinality - 1);
    for (var i = 0; i < cardinality; i++) {
        arr.push(startValue + (step * i));
    }
    return arr;
}

function makeid(length) {
    var result = [];
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result.push(characters.charAt(Math.floor(Math.random() *
            charactersLength)));
    }
    return result.join('');
}

const CardPage = (props) => {
    let [card, setCard] = useState({});
    let [validQty, setValidQty] = useState(false);
    let [qtyAvail, setQtyAvail] = useState(12);
    let [qtyPurchase, setQtyPurchase] = useState(makeArr(1, qtyAvail, qtyAvail))
    let [condition, setCondition] = useState("Near Mint");
    let [qty, setQty] = useState("Select Qty");
    const location = useLocation();

    const handleSelect = (e) => {
        setQty(e)
        setValidQty(true)
    }

    const handleCartAdd = (e) => {
        const key = makeid(10)
        let cartObj = {
            name: card.name,
            subName: card.setName,
            imageUrl: card.imageUrl,
            price: 12.99,
            qty: qty,
            key: key,
        }
        localStorage.setItem('cart:' + key, JSON.stringify(cartObj));
        console.log(Object.keys(localStorage));
    }

    function renderPower() {
        if (card.power || card.toughness) {
            return (
                <p>{card.power}/{card.toughness}</p>
            )
        }
    }

    useEffect(() => {
        setCard(location.state.card);
    }, []);

    return (
        <div>
            <Row className="m-auto align-self-center">
                <Col xs={12} sm={4} xl={2}>
                    <Row>
                        <img style={{margin: '10px'}} src={card.imageUrl} alt={card.name}/>
                    </Row>
                </Col>
                <Col xs={12} sm={8} xl={5}>
                    <Card style={{margin: '10px'}}>
                        <Jumbotron style={{margin: '10px'}}><h2><b>{card.name}</b></h2></Jumbotron>
                        <Card.Subtitle style={{margin: '10px'}}
                                       className="mb-2 text-muted">{card.setName}</Card.Subtitle>
                        <Card.Body>
                            <p>{card.types}</p>
                            <p>{card.originalText}</p>
                            <p><i>{card.flavor}</i></p>
                            {renderPower()}
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} sm={12} xl={5}>
                    <Card style={{margin: '10px'}}>
                        <Card.Title>
                            <Jumbotron fluid>
                                <h2 className="text-center"><b>Buy Now</b></h2>

                            </Jumbotron>

                        </Card.Title>
                        <Card.Body>
                            <p>Buy within the next 2 hours and 15 minutes to qualify for same day delivery through
                                DeliveryApp.</p>
                            <p>Curb side pick-up available.</p>
                            <p className="mb-2 text-muted">Condition: <i>{condition}</i></p>
                            <InputGroup className="mb-3">
                                <DropdownButton
                                    as={InputGroup.Prepend}
                                    variant="outline-primary"
                                    title={qty}
                                    id="input-group-dropdown-1"
                                    onSelect={handleSelect}
                                >
                                    {qtyPurchase.map(size => (
                                        <Dropdown.Item eventKey={size}>{size}</Dropdown.Item>
                                    ))}
                                </DropdownButton>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>of {qtyAvail}</InputGroup.Text>
                                </InputGroup.Prepend>
                            </InputGroup>
                            <OverlayTrigger
                                overlay={<Tooltip id="tooltip-disabled">Wizard! You must select how many scrolls you
                                    want for you spellbook.</Tooltip>}>
                              <span className="d-inline-block">
                                  <Link to="/cart">
                                    <Button
                                        size="lg"
                                        variant="primary"
                                        block disabled={!validQty}
                                        onClick={handleCartAdd}
                                    >Add to cart</Button>{"   "}
                                  </Link>
                              </span>
                            </OverlayTrigger>
                            <Link to="/search">
                                <Button size="lg" variant="outline-secondary" block>Return to
                                    search</Button>
                            </Link>
                        </Card.Body>
                    </Card>
                </Col>

            </Row>
        </div>
    );
}

export default CardPage;
