export default function boxInventory(){

    const box1={
        name:"Strixhaven",
        description:"Magic goes off to college at Strixhaven: School of Mages. Join one of Strixhaven’s five colleges and get in where you fit in among the brightest young spell casters around. Silverquil, Lorehold, Prismari, Witherbloom, Quandrix—each dual-color college has its own unique personality and mechanics. Enrollment starts now. Which college will you choose?",
        src:"https://images.ctfassets.net/s5n2t79q9icq/7efA0jTDL5mmVDqOyoqQVH/eaf29619a69aafdf0f5596a692e8fff2/en_Jl6dXhowVi.png?fm=webp",
        price:"$189"
    }

    const box2={
        name:"Modern Horizons 2",
        description: "Discover the missing pieces to your favorite deck! This set is bursting with everything you need to blast your way through the battlefield. Whether you're a fan of Modern or Commander, this set has something for everyone!",
        price:"$289",
        src: "https://images.ctfassets.net/s5n2t79q9icq/6cJ03V4ux2PzLFKMngCHeZ/d51d652ce1fd778664ad6bb11259e0b8/1hze0ORsx5_EN.png?fm=webp",
    }

    const box3={
        name:"Forgotten Realms",
        description:"Everything you love from Dungeons & Dragons meets Magic in the Forgotten Realms! Play cards featuring fan-favorite characters, and battle with iconic monsters like beholders, mimics, mind flayers, and—of course—legendary dragons!",
        price:"$234",
        src: "https://images.ctfassets.net/s5n2t79q9icq/1WxBS9neLB65mbD4r7ha1l/4f5bf4dad884ec02606e254a3d15357f/en_n1McLdPFBg.png?fm=webp",

    }

    const box4={
        name:"Time Spiral",
        description: "Welcome the new. Relive the great. Time Spiral Remastered revisits fan favorites with a curated set to immerse you in every moment.",
        price:"$123",
        src: "https://images.ctfassets.net/s5n2t79q9icq/7d4rq48l68j6VJXWPlbTBO/b97ba99f80e5edcb7936c9ac1eadca5a/EN_LyLxgyxORQ.png?fm=webp",

    }

    const boxes = [box1, box2, box3, box4];
    return(boxes);
}
